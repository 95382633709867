<template>
  <section id="minhas-instrucoes">
    <b-form-group>
      <label for="instruction">
        {{ title }}
      </label>
      <b-form-input
        class="mb-2"
        v-model="instruction.title"
        placeholder="Definir título para instrução"
      ></b-form-input>
      <VueEditor
        id="instruction-content"
        class="vue_edition"
        placeholder="Insira mais informações para a prescrição de óculos"
        :value="instruction.content"
        :editorToolbar="customToolbar"
        @input="text => instruction.content = text"
        :disabled="!canEdit"
      />
      <b-row class="justify-content-end">
        <b-button
          class="mt-2"
          variant="primary"
          :disabled="!instruction.title || !instruction.content"
          @click="addInstruction"
        >
          Adicionar
        </b-button>
      </b-row>
    </b-form-group>
    <table 
      v-if="canEdit && instructionsCopy"
      class="table-eyecare" 
    >
      <thead>
        <tr>
          <th>Título da instrução</th>
          <th>Descrição da instrução</th>
        </tr>
      </thead>
      <tbody v-if="instructionsCopy.length">
        <tr
          v-for="(instruction, index) in instructionsCopy"
          :key="index"
        >
          <td>
            <p>
              {{ instruction?.title }}
            </p>
          </td>
          <td>
            <span v-html="instruction?.content"></span>
          </td>
          <td class="text-right">
              <div class="more-with-menu">
                <MoreVertical class="more-icon" />
                <div class="menu">
                  <b-button
                    variant="link"
                    @click="edit(index)"
                  >
                    Editar
                  </b-button>
                  <b-button
                    variant="link remove mb-0"
                    @click="remove(index)"
                  >
                    Remover
                  </b-button>
                </div>
              </div>
            </td>
        </tr>
      </tbody>
      <tbody class="text-align-center " v-else>
        <tr>
          <td colspan="3">Nenhum instrução cadastrada.</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { VueEditor } from "vue2-editor"

export default {
  components: { 
    VueEditor,
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
  },
  props: {
    title: {
      default: "Minhas instruções",
      type: String
    },
    canEdit: Boolean,
    instructions: Array,
  },
  data() {
    return {
      instruction: {
        title: null,
        content: null,
      },
      instructionsCopy: null,

      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ],
    }
  },
  methods: {
    getDefaultForm(){
      return {
        title: null,
        content: null,
      }
    },
    addInstruction () {
      this.instruction.id = this.instructionsCopy.length
      this.instructionsCopy.unshift(this.instruction)
      this.instruction = this.getDefaultForm()
      this.emitChange()
    },
    mountInstructionsCopy(){
      if(this.instructions) {
        this.instructionsCopy = this.instructions.map((instruction) => {
          return { ...instruction }
        })
      }
    },
    edit (index) {
      this.instruction = {
        title: this.instructionsCopy[index].title,
        content: this.instructionsCopy[index].content
      }
      this.instructionsCopy.splice(index, 1)
    },
    remove (index) {
      this.instructionsCopy.splice(index, 1)
      this.emitChange()
    },
    emitChange () {
      this.$emit('updateInstructions', this.instructionsCopy.map(el => {
          return {
            title: el.title,
            content: el.content,
          }
        })
      )
    }
  },
  mounted() {
    this.mountInstructionsCopy()
  },
  watch: {
    instructions: {
      handler(value) {
        this.mountInstructionsCopy()
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
#minhas-instrucoes {
  display: flex;
  flex-direction: column;

  fieldset, div {
    width: 100%;
    margin: 0;
  }

  .more-with-menu {
    width: 150px;
  }
}
</style>